<template>
	<div class="padding-top-10 contain_authen">
		<top :bgWhite='true'></top>
		<div class="content_authen bg-white padding-lr-88">
			<div class="padding-top-20 fs24 solid-bottom padding-bottom-15">
				作家认证
			</div>
			<div class="flex align-center justify-center padding-top-30">
				<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
					class="demo-ruleForm">
					<el-form-item label="姓名:" prop="user_name">
						<el-input type="text" v-model="ruleForm.name" autocomplete="off" placeholder="请输入姓名">
						</el-input>
					</el-form-item>
					<el-form-item label="身份证号:" prop="idcard_no">
						<el-input type="text" v-model="ruleForm.idcard_no"   placeholder="请输入身份证号">
						</el-input>
					</el-form-item>
					<el-form-item label="手机号码:" prop="mobile">
						<el-input onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11" v-model.number="ruleForm.mobile" placeholder="请输入手机号"></el-input>
					</el-form-item>
					<el-form-item label="身份证照:" class="">
						<el-upload class="avatar-uploader margin-top-10"
							:action="BaseUrl+'/api/v1/5fc615f7da92b'" :show-file-list="false"
							:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1">
							<el-image v-if="ruleForm.idcard_front" :src="idcard_front_url" class="avatar" fit="cover"></el-image>
							<img v-else src="../../assets/image/card2.png" class="avatar">
							<!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
						</el-upload>
						<p class="text-center fs14 padding-top-10">身份证正面（国徽面）</p>
						<el-upload class="avatar-uploader margin-top-20"
							:action="BaseUrl+'/api/v1/5fc615f7da92b'" :show-file-list="false"
							:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2">
							<el-image v-if="ruleForm.idcard_reverse" :src="idcard_reverse_url" class="avatar" fit="cover"></el-image>
							<img v-else src="../../assets/image/card1.png" class="avatar">
							<!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
						</el-upload>
						<p class="text-center fs14 padding-top-10">身份证反面（人像面）</p>
					</el-form-item>

					<el-form-item class="margin-top-180 margin-bottom-40 margin-left-40">
						<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
							@click="submitForm('ruleForm')">下一步</span>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	// 引入上传图片公共方法
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'authen',
		components: {
			bottom,
			top
		},
		data() {
			var checkUser_name = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('姓名不能为空'));
				}
				if (value.length < 2 || value.length > 6) {
					callback(new Error('请输入身份证真实姓名'));
				}
			};
			var checkCardId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入身份证号'));
				} else if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
					callback(new Error('请输入正确的身份证号'));
				}else{
					 callback();
				}
				
			};
			var checkPhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else if (!/^1[3-9]\d{9}$/.test(value)) {
					callback(new Error('请输入正确的手机号'));
				}else{
					 callback();
				}
			};

			return {
				BaseUrl:this.imgUrl,
				img1: false, //正面
				img2: false, //反面
				idcard_front_url: '', //正面照
				idcard_reverse_url: '', //反面照
				ruleForm: {
					name: '', //姓名
					idcard_no: '', //身份证号码
					mobile: '', //手机号
					idcard_front: '', //正面照
					idcard_reverse: '', //反面照
				},
				rules: {
					name: [{
						validator: checkUser_name,
						trigger: 'blur'
					}],
					idcard_no: [{
						validator: checkCardId,
						trigger: 'blur'
					}],
					mobile: [{
						validator: checkPhone,
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {

		},
		methods: {
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handleAvatarSuccess1(res, file) {
				console.log(res);
				// 开始上传
				// this.ruleForm.idcard_front = URL.createObjectURL(file.raw);
				this.ruleForm.idcard_front = res.data.data.fileId;
				this.idcard_front_url = res.data.data.file;
			},
			beforeAvatarUpload1(file) {
				console.log(file);
				// const isJPG = file.type === ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
				const isLt2M = file.size / 1024 / 1024 < 10;

				// if (!isJPG) {
				// 	this.$message.error('格式不支持!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isLt2M;
			},
			handleAvatarSuccess2(res, file) {
				// this.ruleForm.idcard_reverse = URL.createObjectURL(file.raw);
				this.ruleForm.idcard_reverse = res.data.data.fileId;
				this.idcard_reverse_url = res.data.data.file;
			},
			beforeAvatarUpload2(file) {
				// const isJPG = file.type === ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
				const isLt2M = file.size / 1024 / 1024 < 10;

				// if (!isJPG) {
				// 	this.$message.error('格式不支持!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isLt2M;
			},
			submitForm(formName) {
				// 姓名不存在
				if (!this.ruleForm.name) {
					this.$message.error('请输入姓名');
					return false;
				};
				// 身份证号不存在
				if (!this.ruleForm.idcard_no) {
					this.$message.error('请输入身份证号');
					return false;
				};
				// 手机号码不存在
				if (!this.ruleForm.mobile) {
					this.$message.error('请输入手机号码');
					return false;
				};
				// 
				if (!this.ruleForm.idcard_front) {
					this.$message.error('请先上传身份证正面照');
					return false;
				};
				if (!this.ruleForm.idcard_reverse) {
					this.$message.error('请先上传身份证反面照');
					return false;
				};
				this.$router.replace({
					name:'workUpload',
					params:this.ruleForm
				});

			}
		}
	}
</script>

<style scoped>
	.contain_authen {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content_authen {
		width: 52%;
		height: auto;
		margin: 80px auto;
	}
	.avatar {
		width: 300px !important;
		height: 190px !important;
		display: block;
	}
	/deep/.el-input {
		width: 320px;
		height: 36px;
	}
</style>
